a {
  text-decoration: none !important; }
  a:hover {
    text-decoration: none; }

body,
#root {
  overflow-x: hidden; }

.btn-primary:active,
.btn-primary:hover,
.btn-check:focus,
.btn-primary:focus,
.btn-primary:focus {
  background: #be9142 !important; }

.topbar {
  background: #e3cc80;
  padding: 8px 0 !important; }
  .topbar p {
    font-family: "Barlow" !important;
    font-size: 14px;
    font-weight: 500; }
    .topbar p .bi {
      color: #000;
      margin-right: 8px; }
  .topbar .text-right {
    text-align: right; }

.navbar {
  background: #000 !important;
  font-size: 14px;
  padding: 0 !important;
  box-shadow: 12px 19px 62px 0px rgba(13, 52, 79, 0.3); }
  .navbar .navbar-brand img {
    width: 150px; }
  .navbar .navbar-nav .nav-link {
    margin: 5px 15px;
    font-family: "Playfair Display";
    color: #fff !important;
    letter-spacing: 1.5px; }
    .navbar .navbar-nav .nav-link:hover {
      color: #e3cc80 !important; }
  .navbar .navbar-nav .dropdown-menu {
    width: 300px;
    padding: 0;
    border-radius: 0; }
    .navbar .navbar-nav .dropdown-menu .dropdown-item {
      font-family: "Playfair Display";
      padding: 10px 15px; }
  .navbar .navbar-nav .btn {
    background: #e3cc80;
    color: #000 !important;
    padding: 5px 25px !important; }
    .navbar .navbar-nav .btn:hover {
      color: #fff !important; }
  .navbar .dropdown-item.active,
  .navbar .dropdown-item:active {
    background: #e3cc80;
    color: #000; }

.slider {
  position: relative;
  background-color: #000; }
  .slider .carousel-indicators li {
    height: 20px;
    width: 20px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 4px;
    cursor: pointer;
    position: relative; }
    .slider .carousel-indicators li::after {
      content: '';
      position: absolute;
      left: 8px;
      top: 9px;
      border-radius: 100px;
      height: 3px;
      width: 3px;
      background-color: #000; }
  .slider .carousel-indicators .active {
    background: #fff; }

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1); }
  to {
    -webkit-transform: scale(1.5, 1.5); } }

@keyframes zoom {
  from {
    transform: scale(1, 1); }
  to {
    transform: scale(1.5, 1.5); } }
  .slider .carousel-inner .carousel-item img {
    -webkit-animation: zoom 20s;
    animation: zoom 20s; }

.theartist {
  padding: 80px 0;
  background: #000;
  position: relative; }
  .theartist .box {
    padding: 20px; }
    .theartist .box h2 {
      font-family: "Playfair Display";
      font-size: 42px;
      margin-bottom: 30px;
      color: #e3cc80; }
    .theartist .box p {
      font-size: 16px;
      font-family: "Barlow";
      line-height: 36px;
      font-weight: 300;
      text-align: justify;
      color: #fff;
      font-family: "Lato";
      letter-spacing: 1px; }
    .theartist .box .btn {
      background: #e3cc80;
      color: #000;
      margin: 15px 0;
      padding: 15px 35px;
      font-family: "Playfair Display";
      border: 0; }
  .theartist img {
    border-radius: 10px;
    padding: 2px; }

.already-beautiful {
  padding: 150px 0;
  background: #000;
  position: relative;
  overflow: hidden;
  text-align: center; }
  .already-beautiful .already-beautiful-background {
    background-position: 50% 50%;
    background-size: cover !important;
    background-attachment: fixed !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: grayscale(1);
    opacity: .5; }
  .already-beautiful .container {
    position: relative;
    z-index: 1; }
  .already-beautiful h3 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    color: #e3cc80;
    font-style: italic; }
  .already-beautiful p {
    color: #fff;
    text-align: center;
    font-family: "Lato";
    font-style: 16px;
    font-weight: 400;
    letter-spacing: 1px; }

.services {
  padding: 70px 0;
  background: #000;
  position: relative; }
  .services h4 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    color: #fff;
    text-align: center;
    padding-bottom: 40px; }
  .services .service-box {
    overflow: hidden; }
    .services .service-box img {
      padding: 20px; }
    .services .service-box:hover img {
      transform: scale(1.1);
      transition: 1s; }
  .services p {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    font-size: 20px;
    text-align: center;
    color: #e3cc80;
    letter-spacing: 1px;
    margin-bottom: 15px;
    font-weight: 600; }
    .services p span {
      color: #fff;
      text-align: center;
      font-family: "Lato";
      font-style: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      font-size: 14px;
      color: #fff; }
  .services .btn {
    background: #e3cc80;
    margin: 15px 0;
    padding: 15px 35px;
    font-family: "Playfair Display";
    border: 0;
    color: #000;
    background: no-repeat;
    border: 1px solid #e3cc80;
    padding: 8px 25px;
    font-style: 14px;
    color: #fff; }
    .services .btn:hover {
      background: #be9142;
      color: #000; }
  .services .service-text {
    text-align: center;
    margin-bottom: 30px; }

.bridal-jewellery {
  background: #1a1a1a;
  position: relative; }
  .bridal-jewellery .container-fluid {
    padding: 0; }
    .bridal-jewellery .container-fluid .row {
      margin: 0; }
  .bridal-jewellery h5 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    color: #e3cc80;
    font-size: 36px; }
  .bridal-jewellery p {
    color: #fff;
    text-align: center;
    font-family: "Lato";
    font-style: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: justify;
    color: #fff; }
  .bridal-jewellery .btn {
    background: #e3cc80;
    margin: 15px 0;
    padding: 15px 35px;
    font-family: "Playfair Display";
    border: 0;
    color: #000; }
  .bridal-jewellery img {
    border-radius: 0px; }
  .bridal-jewellery .bridal {
    padding: 20px; }

.testimonials {
  padding: 70px 0 110px 0;
  background: #000;
  overflow: hidden;
  position: relative; }
  .testimonials .testimonails-background {
    background-position: center;
    background-size: cover !important;
    background-attachment: fixed !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: grayscale(1);
    opacity: .4; }
  .testimonials h6 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    color: #fff;
    text-align: center;
    padding-bottom: 60px; }
  .testimonials p {
    color: #fff;
    text-align: center;
    font-family: "Lato";
    font-style: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 300;
    line-height: 30px;
    font-size: 16px; }
    .testimonials p strong {
      color: #be9142;
      font-family: "Playfair Display";
      font-size: 20px; }
  .testimonials .testimonial-box {
    text-align: center; }
    .testimonials .testimonial-box .bi {
      display: block;
      font-size: 30px;
      padding: 15px 0; }
    .testimonials .testimonial-box img {
      margin-bottom: 20px; }
  .testimonials .carousel-indicators {
    bottom: -55px; }
    .testimonials .carousel-indicators li {
      height: 15px;
      width: 15px;
      border-radius: 100px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 4px;
      cursor: pointer; }
    .testimonials .carousel-indicators .active {
      background: #fff; }
  .testimonials .sr-only {
    display: none; }

.gallery {
  padding: 70px 0;
  position: relative;
  background-color: #000;
  text-align: center; }
  .gallery h2 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    color: #e3cc80;
    text-align: center;
    padding-bottom: 30px; }
  .gallery .gallery-box {
    padding: 10px;
    margin-bottom: 25px; }
    .gallery .gallery-box:hover img {
      transform: scale(1.1);
      transition: 1s; }
  .gallery .btn {
    background: #e3cc80;
    margin: 15px 0;
    padding: 15px 35px;
    font-family: "Playfair Display";
    border: 0;
    color: #000; }

.footer {
  background-color: #191919;
  padding: 70px 0; }
  .footer h3,
  .footer h4,
  .footer h5,
  .footer h6 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    font-size: 24px;
    margin-bottom: 30px;
    color: #e3cc80; }
  .footer p {
    color: #fff;
    text-align: left;
    font-family: "Barlow";
    display: flex;
    letter-spacing: normal;
    font-size: 14px; }
    .footer p strong {
      font-family: "Playfair Display"; }
    .footer p .bi {
      margin-right: 10px;
      color: #e3cc80; }
  .footer ul {
    padding-left: 0; }
    .footer ul li {
      font-family: "Barlow";
      text-align: left;
      display: flex;
      letter-spacing: normal;
      margin-bottom: 15px;
      font-size: 14px; }
      .footer ul li a {
        color: #fff; }
  .footer .footer-icon a {
    color: #000;
    margin-right: 5px;
    background: #e3cc80;
    height: 40px;
    border-radius: 2px;
    width: 40px;
    display: inline-block;
    line-height: 38px;
    text-align: center; }

.copyright {
  background: #000;
  padding: 15px 0; }
  .copyright p {
    color: #e3cc80;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0;
    font-family: "Barlow";
    text-align: center; }

#back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  color: #000;
  margin-right: 5px;
  background: #fff;
  height: 40px;
  border-radius: 0px;
  width: 40px;
  display: inline-block;
  line-height: 38px;
  text-align: center; }

.already-beautiful .already-beautiful-background,
.testimonials .testimonails-background {
  background-position: 54% 50% !important; }

.common-banner {
  position: relative; }
  .common-banner .container {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center; }
  .common-banner h1 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    margin-bottom: 20px;
    color: #e3cc80;
    font-size: 52px; }
  .common-banner .phone_banner {
    display: none; }
  .common-banner nav .breadcrumb a {
    color: #fff;
    font-size: 16px;
    font-family: "Lato";
    letter-spacing: 1px;
    cursor: default; }
  .common-banner nav .breadcrumb .breadcrumb-item.active {
    font-size: 16px;
    font-family: "Lato";
    letter-spacing: 1px;
    color: #e3cc80 !important;
    cursor: pointer; }

.contact-content {
  padding: 70px 0;
  background-color: #000;
  position: relative; }
  .contact-content h2 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    text-align: center;
    margin-bottom: 40px;
    font-size: 36px;
    color: #fff; }
  .contact-content h3 {
    font-family: "Playfair Display";
    font-size: 42px;
    margin-bottom: 30px;
    margin-bottom: 40px;
    font-size: 36px;
    color: #fff; }
  .contact-content .box {
    background: #e3cc80;
    padding: 20px;
    min-height: 180px;
    margin: 20px 0;
    text-align: center; }
    .contact-content .box p {
      color: #fff;
      text-align: center;
      font-family: "Lato";
      font-style: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      color: #000; }
      .contact-content .box p strong {
        font-family: "Playfair Display";
        font-size: 20px; }
  .contact-content label {
    color: #fff;
    text-align: center;
    font-family: "Lato";
    font-style: 16px;
    font-weight: 400;
    letter-spacing: 1px; }
  .contact-content input,
  .contact-content textarea {
    background: none;
    border-color: #e3cc80;
    color: #e3cc80;
    border-radius: 0;
    font-family: "Playfair Display"; }
  .contact-content input {
    height: 45px; }
  .contact-content .form-control::placeholder {
    color: #e3cc80; }
  .contact-content .form-control:focus {
    background: none;
    color: #fff; }
  .contact-content .btn {
    background: #e3cc80;
    margin: 15px 0;
    padding: 15px 35px;
    font-family: "Playfair Display";
    border: 0;
    color: #000; }
  .contact-content .contact-form {
    padding-left: 30px; }

.inner-content .box {
  padding-left: 0; }

.experience {
  background: #000;
  padding: 100px 0;
  position: relative; }
  .experience .theartist-bg {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: .3;
    background-position: 50% 50% !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    filter: grayscale(1); }
  .experience .container {
    position: relative; }
  .experience .box {
    background: #e3cc80;
    padding: 35px 25px;
    text-align: center;
    margin-bottom: 15px; }
    .experience .box h3 {
      font-family: "Playfair Display";
      font-size: 42px;
      margin-bottom: 30px;
      color: #000;
      font-family: "Barlow";
      font-weight: 700;
      margin-bottom: 10px; }
    .experience .box p {
      font-family: "Playfair Display";
      font-size: 42px;
      margin-bottom: 30px;
      color: #000;
      font-size: 18px;
      letter-spacing: 1px; }

.content {
  padding: 70px 0;
  position: relative;
  background: #000; }

.video-iframe iframe {
  border: 1px solid #e3cc80;
  margin-bottom: 15px; }

.pricing {
  padding-top: 150px; }
  .pricing .col-lg-4 {
    padding: 0; }
    .pricing .col-lg-4:nth-child(2) {
      margin-top: -50px;
      position: relative;
      z-index: 2; }
      .pricing .col-lg-4:nth-child(2) .card {
        padding: 50px 0;
        border-radius: 0px; }
        .pricing .col-lg-4:nth-child(2) .card .h6 {
          margin-top: -75px; }
        .pricing .col-lg-4:nth-child(2) .card .card-body {
          margin-top: 50px;
          min-height: 500px; }
          .pricing .col-lg-4:nth-child(2) .card .card-body .bride {
            text-align: right; }
  .pricing .card {
    background: #222;
    border-radius: 0;
    border: 0;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.5); }
    .pricing .card .h6 {
      font-size: 18px;
      font-family: "Barlow";
      font-weight: 700;
      padding: 15px 15px;
      position: relative;
      z-index: 2;
      background: #e3cc80;
      margin-top: -25px; }
      .pricing .card .h6::after {
        content: '';
        position: absolute;
        border-left: 20px solid transparent;
        top: 0;
        bottom: 0;
        right: 0;
        border-top: 25px solid #000;
        border-bottom: 25px solid; }
      .pricing .card .h6::before {
        content: '';
        position: absolute;
        border-right: 20px solid transparent;
        top: 0;
        bottom: 0;
        left: 0;
        border-top: 25px solid #000;
        border-bottom: 25px solid; }
    .pricing .card .card-body {
      min-height: 520px;
      margin-top: 10px; }
    .pricing .card ul li {
      font-size: 14px;
      font-family: "Barlow";
      display: flex;
      justify-content: space-around;
      text-align: left;
      padding: 15px 0;
      font-size: 16px;
      font-weight: 600; }
      .pricing .card ul li strong {
        font-family: "Playfair Display";
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 1;
        color: #e3cc80; }
        .pricing .card ul li strong:first-child {
          width: 70%; }
      .pricing .card ul li span {
        color: #e3cc80;
        font-weight: 400; }
        .pricing .card ul li span:first-child {
          width: 70%;
          text-align: left; }
  .pricing .col-lg-6 ul li strong {
    font-family: "Playfair Display";
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 1;
    color: #e3cc80;
    width: 30.3% !important; }
  .pricing .col-lg-6 ul li span {
    color: #e3cc80;
    font-weight: 400;
    width: 30.3% !important;
    text-align: left; }

@media (min-width: 1200px) and (max-width: 1420px) {
  .navbar .navbar-nav .nav-link {
    margin: 0 3px;
    white-space: nowrap;
    letter-spacing: .5px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .bridal-jewellery img {
    margin-bottom: 20px; }
  .navbar .navbar-nav .nav-link {
    margin: 0 0px;
    white-space: nowrap;
    letter-spacing: normal; } }

@media (max-width: 991px) {
  .topbar {
    text-align: center; }
    .topbar .text-right {
      text-align: center !important;
      display: none; }
  .navbar-light .navbar-toggler {
    background: none;
    border: 0;
    border-radius: 0; }
    .navbar-light .navbar-toggler .navbar-toggler-icon {
      color: #e3cc80;
      filter: invert(1); }
  .bridal-jewellery img {
    margin-bottom: 20px; }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    z-index: 9;
    background: #000;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    box-shadow: 12px 19px 62px 0px rgba(13, 52, 79, 0.3); }
  .navbar .navbar-nav .nav-link {
    color: #fff !important;
    font-size: 16px; }
  .navbar .navbar-nav .dropdown-menu {
    width: 100%; }
  .navbar .navbar-nav .btn {
    background: none !important;
    text-align: left;
    padding: inherit !important; }
  .theartist .box {
    padding: 0; } }

@media (max-width: 767px) {
  .theartist,
  .services {
    padding: 50px 0; }
  .container {
    max-width: 100%; }
  .services {
    text-align: center; }
  .slider .carousel-indicators li {
    height: 13px;
    width: 13px; }
    .slider .carousel-indicators li::after {
      left: 5px;
      top: 5px; }
  .theartist .box h2 {
    font-size: 28px; }
  .theartist .box {
    text-align: center; }
    .theartist .box p {
      text-align: center; }
  .common-banner {
    padding: 100px 0; }
    .common-banner img {
      display: none; }
    .common-banner h1 {
      font-size: 36px;
      margin-bottom: 5px; }
    .common-banner .breadcrumb li {
      font-size: 14px;
      font-family: "Barlow"; }
    .common-banner .container {
      position: relative; }
  .phone_banner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block !important;
    background-position: 65% 50% !important; }
  .services h4 {
    margin-bottom: 0; } }

@media (max-width: 520px) {
  .topbar p {
    font-size: 13px;
    font-weight: 400; }
  .topbar .me-4 {
    margin: 0 !important; }
  .common-banner h1 {
    font-size: 24px;
    margin-bottom: 0px; }
  .common-banner .breadcrumb li {
    font-size: 14px; } }
